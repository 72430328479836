@import "../../../styles/common/colors";

.container {
    width: 100%;
    height: 25px;
    border-radius: 2px;
    background-color: $snow;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}

.link {
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 18px;
}

.copy-button {
    background: transparent;
    border: none;
    outline: none;
    padding: 0;
    cursor: pointer;
    fill: $gray;

    .icon {
        height: 16.5px;
        width: 14.25px;
    }

    &:hover {
        fill: $sky-blue;
    }
}